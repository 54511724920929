import React, {useRef, useState} from 'react';
import Popup from "./Popup";
import {sendMessageToChatRequest} from "../../http/chatRequests";
import {sendMessageToFolderRequest} from "../../http/foldersRequests";
import EmojiPicker from "emoji-picker-react";
import ContentEditable from "react-contenteditable";
import MDEditor from '@uiw/react-md-editor';

const SendMessagePopup = ({setIsPopupOpen, isPopupOpen, chatOrFolder, type}) => {
    const [message, setMessage] = useState('');
    const [windowsMessage, setWindowsMessage] = useState('');
    const [isEmojiOpen, setIsEmojiOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isWindowsType, setIsWindowsType] = useState(localStorage.getItem('isWindows') === 'true' || false);
    const [isRequestSending, setIsRequestSending] = useState(false);
    const [isScheduled, setIsScheduled] = useState(false);
    const [sendingTime, setSendingTime] = useState('');

    const ref = useRef(null);

    const previewHTML = message
        .replace(/<p ([a-z][A-Za-z0-9="\-:;& ]*)><br><\/p>/g, '\n')
        .replace(/<p(.*?\")>/g, '')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<span class="s1" style="text-decoration-line: underline;">(.*?)<\/span>/g, '<u>$1</u>')
        .replace(/<span(.*?\")>/g, '')
        .replace(/<span>/g, '')
        .replace(/<\/span>/g, '')
        .replace(/<br>/g, '')
        .replace(/&nbsp;/g, ' ');


    const handleSendMessage = () => {
        const messageToSend = isWindowsType ? windowsMessage : previewHTML;
        if (!messageToSend.length) {
            alert('Введите сообщение');
            return;
        }
        setIsRequestSending(true);

        const types = {
            chat: sendMessageToChatRequest, folder: sendMessageToFolderRequest
        }
        const formData = new FormData();
        formData.append('id', chatOrFolder._id);
        formData.append('message', messageToSend);
        if(isScheduled) {
            formData.append('sendingIn', new Date(sendingTime).getTime() - new Date().getTime());
        }
        if (selectedFile) {
            formData.append('image', selectedFile);
        }

        types[type](formData)
            .then(data => {
                alert(data.data.message);
                setIsPopupOpen(false);
            })
            .catch(err => {
                alert(err.response.data.message);
            }).finally(() => {
            setIsRequestSending(false);
        })
    }

    const change = (sign) => {
        const selection = window.getSelection();
        const text = ref.current.value;
        const start = selection.anchorOffset;
        const end = selection.focusOffset;
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        const selected = text.substring(start, end);
        const bolded = `${sign}${selected}${sign}`;
        const result = before + bolded + after;
        setWindowsMessage(result);
    }

    const createLink = () => {
        const selection = window.getSelection();
        const text = ref.current.value;
        const start = selection.anchorOffset;
        const end = selection.focusOffset;
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        const selected = text.substring(start, end);
        const link = `[${selected}](https://link.com)`;
        const result = before + link + after;
        setWindowsMessage(result);
    }

    const handleChange = (e) => {
        localStorage.setItem('isWindows', e.target.value);
        setIsWindowsType(e.target.value === 'true');
    }


    return (
        <Popup isOpen={isPopupOpen} title={<>Send message to {type}<br/>"{chatOrFolder.title}"</>}
               setIsOpen={setIsPopupOpen}>
            <div>
                <div className='textarea_sendMessage_block'>
                    <input type="radio" name='device' id='macIco' checked={!isWindowsType} value={false}
                           onChange={handleChange}/>
                    <label htmlFor="macIco">Mac</label>

                    <input type="radio" name='device' id='windowsIco' checked={isWindowsType} value={true}
                           onChange={handleChange}/>
                    <label htmlFor="windowsIco">Windows</label>

                    {isWindowsType ? (<div className="editor">

                        <div className="editor_body">
                            <MDEditor
                                value={windowsMessage}
                                onChange={setWindowsMessage}
                                height={300}
                                preview='live'
                                data-color-mode='light'
                                commands={[{
                                    name: 'Bold',
                                    keyCommand: 'Bold',
                                    buttonProps: {'aria-label': 'Bold'},
                                    icon: <span><b>B</b></span>,
                                    execute: (state, api) => {
                                        // add ** before and after selection
                                        const newSelection = `<b>${state.selectedText}</b>`;
                                        api.replaceSelection(newSelection);
                                    },
                                }, {
                                    name: 'Italic',
                                    keyCommand: 'Italic',
                                    buttonProps: {'aria-label': 'Italic'},
                                    icon: <span><i>I</i></span>,
                                    execute: (state, api) => {
                                        // add ** before and after selection
                                        const newSelection = `<i>${state.selectedText}</i>`;
                                        api.replaceSelection(newSelection);
                                    },
                                }, {
                                    name: 'Underline',
                                    keyCommand: 'Underline',
                                    buttonProps: {'aria-label': 'Underline'},
                                    icon: <span><u>U</u></span>,
                                    execute: (state, api) => {
                                        // add ** before and after selection
                                        const newSelection = `<u>${state.selectedText}</u>`;
                                        api.replaceSelection(newSelection);
                                    },
                                }, {
                                    name: 'Strikethrough',
                                    keyCommand: 'Strikethrough',
                                    buttonProps: {'aria-label': 'Strikethrough'},
                                    icon: <span><s>S</s></span>,
                                    execute: (state, api) => {
                                        // add ** before and after selection
                                        const newSelection = `<s>${state.selectedText}</s>`;
                                        api.replaceSelection(newSelection);
                                    },
                                }, {
                                    name: 'Link',
                                    keyCommand: 'Link',
                                    buttonProps: {'aria-label': 'Link'},
                                    icon: <span>Link</span>,
                                    execute: (state, api) => {
                                        // add ** before and after selection
                                        const newSelection = `<a href="https://link.com">${state.selectedText}</a>`;
                                        api.replaceSelection(newSelection);
                                    },
                                },

                                ]}
                            />


                        </div>
                    </div>) : (<div className="editor_body_full_width">
                        <ContentEditable
                            onChange={(e) => setMessage(e.target.value)}
                            html={message}
                            disabled={false}
                            tagName='div'
                            className='editor_tab'
                        />
                    </div>)}

                </div>
                {isEmojiOpen && (<div className='emoji_container'>
                    <div className='emojiPicker_sendMessage'>

                        <EmojiPicker
                            onEmojiClick={(emojiObject, e) => {
                                setMessage(message + emojiObject.emoji)
                            }}
                            autoFocusSearch={false}
                            disableAutoFocus={true}
                            disableSkinTonePicker={true}
                            height={500}
                            lazyLoadEmojis={true}
                            suggestedEmojisMode={false}
                            bottomBarMode={false}
                            previewConfig={{showPreview: false}}
                        />
                    </div>
                </div>)}
                {/*    send by timer */}
                <div className='sendByTimer_block'>
                    <input type="checkbox" id='scheduled' onChange={(e) => setIsScheduled(e.target.checked)}/>
                    <label htmlFor="scheduled">Отправить позжe</label>
                    {isScheduled && (
                        <div className='sendByTimer_block_content'>
                            <input type="datetime-local" onChange={(e) => setSendingTime(e.target.value)}/>
                        </div>
                    )}
                </div>


                <div className='input_file_sendMessage'>
                    <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])}/>
                </div>
                <div className="service_buttons_left">
                    <button className='buttonFailure' onClick={() => setIsPopupOpen(false)}>Cancel</button>
                </div>
                <div className="service_buttons_right">
                    <button className='buttonSuccess' onClick={handleSendMessage} disabled={isRequestSending}>Send
                    </button>
                </div>
            </div>
        </Popup>);
};

export default SendMessagePopup